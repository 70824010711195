"use client";

import type { ReactNode } from "react";
import React, { createContext, useContext, useState } from "react";
import Toast from "./toast";

type ToastContentType = { title: string | ReactNode; body?: string };

interface ToastContextType {
  addToast: (content: ToastContentType) => void;
  removeToast: (title: string) => void;
  toasts: { id: string; content: ToastContentType }[];
}

const toastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(toastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<{ id: string; content: ToastContentType }[]>([]);

  const randomId = Math.floor(Math.random() * 100000).toString();

  const addToast = (content: ToastContentType) => {
    setToasts((prev) => [...prev, { id: randomId, content }]);

    setTimeout(() => {
      removeToast(randomId);
    }, 3000);
  };

  const removeToast = (id: string) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  return (
    <toastContext.Provider value={{ addToast, removeToast, toasts }}>
      {toasts.map((toast, index) => (
        <Toast key={toast.id} toast={toast} />
      ))}
      {children}
    </toastContext.Provider>
  );
};
