"use client";

import { APIProvider } from "@vis.gl/react-google-maps";
import { useState } from "react";

export default function MapProvider({ children }: { children: React.ReactNode }) {
  const [mapLoaded, setMapLoaded] = useState(false);
  return (
    <APIProvider
      apiKey={"AIzaSyDo7qytAC-yVsZV41eY6Fm2Z8e0ZRsuHbo"}
      libraries={["marker", "places"]}
      language="en"
      onLoad={() => {
        setMapLoaded(true);
      }}
    >
      {mapLoaded && children}
    </APIProvider>
  );
}
