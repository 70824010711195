"use client";

import { useTransition } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import type { userInterface } from "@/components/user-form-actions";
import { updateUser } from "@/components/user-form-actions";
import LoadingSpinner from "@/components/loading-spinner";
import { useRouter } from "next/navigation";
import type { User } from "@prisma/client";
import { useToast } from "@/components/toast-context";
import CreateTask from "@/lib/create-task";

const userSchema: yup.ObjectSchema<Omit<userInterface, "userId" | "image" | "profileCompletedAt">> = yup
  .object()
  .shape({
    displayName: yup.string().max(30, "Max 30 characters").required("This field is required"),
    location: yup.string().default(""),
    url: yup.string().default(""),
    aboutMe: yup.string().default(""),
  });

export default function CompleteProfileModal({ user }: { user: User }) {
  const [open, setOpen] = useState(true);
  const { addToast } = useToast();

  const [isPending, startTransition] = useTransition();

  const initialValues: userInterface = {
    displayName: user.displayName || user.name || "",
    location: user?.location ?? "",
    url: user?.url ?? "",
    aboutMe: user?.aboutMe || "",
    image: user?.image || "",
  };

  console.log(initialValues);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:px-6 sm:pb-7 sm:pt-2">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl font-semibold leading-10 text-gray-900">
                      Complete your profile
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tell us about yourself - you can update your profile anytime.
                      </p>
                    </div>
                    <LoadingSpinner loading={isPending} />
                    <Formik
                      initialValues={initialValues}
                      validationSchema={userSchema}
                      validateOnChange={false}
                      onSubmit={async (values, actions) => {
                        startTransition(async () => {
                          values.profileCompletedAt = new Date();
                          await updateUser(values, true);

                          addToast({ title: "Profile updated!" });
                          setOpen(false);
                        });
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="mt-10 flex flex-col gap-y-2 text-left">
                            <div className="">
                              <label
                                htmlFor="displayName"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Display name
                              </label>
                              <div className="mt-1">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cta">
                                  <Field
                                    type="text"
                                    name="displayName"
                                    id="displayName"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {errors.displayName && touched.displayName ? (
                                  <div className="text-red-500">{errors.displayName}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="">
                              <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                Location
                              </label>
                              <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cta sm:max-w-md">
                                  <Field
                                    type="text"
                                    name="location"
                                    id="location"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {errors.location && touched.location ? (
                                  <div className="text-red-500">{errors.location}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="">
                              <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                Url
                              </label>
                              <div className="mt-1">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cta sm:max-w-md">
                                  <Field
                                    type="text"
                                    name="url"
                                    id="url"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {errors.url && touched.url ? <div className="text-red-500">{errors.url}</div> : null}
                              </div>
                            </div>

                            <div className="">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                About me
                              </label>
                              <div className="mt-1">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cta">
                                  <Field
                                    type="text"
                                    name="aboutMe"
                                    id="aboutMe"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    component="textarea"
                                    rows={5}
                                  />
                                </div>
                                {errors.aboutMe && touched.aboutMe ? (
                                  <div className="text-red-500">{errors.aboutMe}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="">
                              <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button
                                  type="submit"
                                  className="w-full rounded-md bg-cta px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cta-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cta"
                                >
                                  Complete profile
                                </button>
                              </div>

                              <div className="pt-4">
                                Need more time to write “About me”? You can complete your profile without it and update
                                your profile later.
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
